import React from "react";
import { styled } from "@mui/system";
import bg from "../assets/02bg.png";
import count from "../assets/mapCount.svg"
import { Box, Typography } from "@mui/material";
import { useLanguage } from '../context/LanguageContext';
import line2 from '../assets/headerline.png'



const BackgroundBox = styled(Box)(({ theme }) => ({
  
}));



const HeaderLine = styled("div")({
  backgroundColor: "#000",
  padding: "0px 100px",
  "@media (max-width:767px)": {
    padding: "0 20px",
  },
});
const Li = styled("li")({
  marginBottom: "35px",
  display: "inline-block",
  width:'100%'
});



const Banner = styled("div")({
  overflow: "hidden",
 
  position: "relative",

  backgroundImage: `url(${bg})`,
  backgroundSize: "cover",
  backgroundPosition: "bottom center",
  backgroundRepeat:'no-repeat',
  padding:'100px',
  "@media (max-width:767px)": {
    padding: "50px 30px",
    backgroundSize:'contain'
  },
});

const MediaSection = () => {
  const { translations } = useLanguage();
  
  return (
    <div style={{textAlign:'left'}}>

    <Banner>
    <div style={{lineHeight:'100px', width:'100%'}}>
    <Typography variant="h2">关于 Teleport 特力博</Typography>
    <img src={line2} alt="line"  style={{width:'100%'}}/>
    </div>

    <h3 style={{margin:0, fontSize:'45px',lineHeight:'1.3'}}>→</h3>
    <ul style={{fontSize:'28px', lineHeight:'60px', listStyleType:'none', margin: 0,
    padding: 0}}>
    <Li>特力博 (Teleport) 成立于 2018 年，是一家综合物流服务提供商。</Li>
    <Li>特力博拥有东南亚最出色、最广泛的航空物流网络，联通该地区的二线和三线城市。</Li>
    <Li>特力博凭借独特的网络优势，可将货物运输至东南亚超过85个城市，覆盖该地区 80% 的范围，并扩展至亚太地区 164 个中心。</Li>
</ul>
<div style={{fontSize:'45px',lineHeight:'1.3'}}>
<h3 style={{margin:0}}>→</h3>
<ul style={{fontWeight:'bold', padding:0, margin: 0,}}>东南亚最出色的航空物流网络</ul>
</div>
      <img src={count} alt="count"/>
    
    </Banner>
    </div>
  );
};

export default MediaSection;