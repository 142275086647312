import React from "react";
import {
  Grid,
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import usbNetwork from "../assets/USP_network.png";
import { useLanguage } from '../context/LanguageContext';

const MoveThingsBetter = () => {
  const { translations } = useLanguage();
  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f4",
        color: "black",
        minHeight: "20vh",
      }}
    >
      <Grid
        container
        spacing={0}
        direction={{ xs: "row", md: "column" }}
        alignItems="center"
      >
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              boxShadow: "none",
              backgroundColor: "transparent",
              borderRadius: 0,
            }}
          >
            {/* <Box sx={{ display: "flex", flexDirection: "row", width: {md:"50%"} }}>
              <CardMedia
                component="img"
                sx={{ width: "100%", height: "auto" }}
                image={usbNetwork}
                alt="usbNetwork"
              />
            </Box> */}
            <Box sx={{ display: "flex", flexDirection: "row", width: {md:"100%"} }}>
              <CardContent
                sx={{
                  flex: "1",
                  padding: { xs: 2, md: 10 },
                  textAlign: "left",
                  color: "#000",
                }}
              >
                <Typography variant="h3" gutterBottom>
                与我们一同启航
                </Typography>
              </CardContent>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MoveThingsBetter;
