import React, { createContext, useState, useContext } from 'react';
import en from '../translations/en.json';
import cn from '../translations/cn.json';

// Create a new context object
const LanguageContext = createContext();

// Custom hook to use the language context
export const useLanguage = () => {
  return useContext(LanguageContext);
};

// Provider component that wraps your app
export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('cn'); // Default language is English
  const [translations, setTranslations] = useState(en);
  const changeLanguage = (lang) => {
    setLanguage(lang);
    if(lang==='en')
      setTranslations(en);
    else
    setTranslations(cn);

  };

  return (
    <LanguageContext.Provider value={{ language,translations, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
