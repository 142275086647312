
import React from 'react';
import Header from "./header";


import LandingBanner from "./landingBanner";
import MediaSection from "./mediaSection";
import SupersetEmbed from "./supersetDashboard";
import MovementOnTheWay from "./MoveItYourWay";
import MoveThingsBetter from "./MoveThingsBetter";
import BrandsWeMove from "./BrandsWeMove";


const Home = () => {
  return (
    <>
   
        <div className="App">
          <Header />
          <LandingBanner />
          <MediaSection />
        <SupersetEmbed/>
          {/* <PromiseToYou /> */}
          <MovementOnTheWay />
          <BrandsWeMove />
          <MoveThingsBetter />
         
        </div>
     
    </>
  );
};

export default Home;
