
import React from 'react';


const NotFound = () => {
  return (
    <>
 
    <div style={{ textAlign: "center", marginTop: "50px", marginBottom:"200px" }}>
      <h1>404 - Page Not Found</h1>
      <p>Sorry, we couldn't find the page you're looking for. Please check the URL and try again.</p>
    </div>
    </>
  );
};

export default NotFound;
