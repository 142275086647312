import React from "react";
import { Grid, Box, Typography, Button, IconButton } from "@mui/material";
import { LinkedIn, Instagram, Facebook, Email } from "@mui/icons-material";
import LinkIcon from "@mui/icons-material/Link";
import teleport from "../assets/Teleport.png";
import qr from "../assets/qr.png"
import police from "../assets/police.png"
import { useLanguage } from '../context/LanguageContext';

const Footer = () => {
  const { translations } = useLanguage();
  const socialMediaInfo = [
    { component: LinkIcon, href: "https://www.home.teleport.asia/" },
    {
      component: LinkedIn,
      href: "https://www.linkedin.com/company/teleportasia/",
    },
    { component: Instagram, href: "https://www.instagram.com/teleportasia/" },
    { component: Facebook, href: "https://www.instagram.com/teleportasia/" },
  ];
  return (
    <Box sx={{ color: "#fff", padding: { xs: "2rem", md: "3rem 5rem" } }}>
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: "3rem" }}>
        <img src={teleport} alt="Teleport Logo" style={{ maxHeight: "40px" }} />
      </Box>
      <Grid container spacing={4}>
        {/* Logo and Address Section */}
        <Grid item xs={12} md={4}>
          <Typography
            variant="body2"
            component="div"
            sx={{ textAlign: "left" }}
          >
          特力博商务服务（深圳）有限公司 (91440300MA5HJNAB1X)
            <br />
            Teleport Commercial Service (Shenzhen) Limited 
            <br />
            中国广东省深圳市宝安区星航中心21楼G室<br />
            0755-23097647
          
          </Typography>
          <Typography
            variant="body2"
            component="div"
            sx={{ marginTop: "4rem", textAlign: "left" }}
          >
            {translations.footer6}
          </Typography>

      <div className="footer-links" style={{textAlign:'left', paddingLeft:0,fontSize:'14px'}} > < a href = "https://beian.miit.gov.cn/" target = "_blank" >您的备案号 : </a > 
          < a href = "https://beian.miit.gov.cn/" target = "_blank" >粤ICP备2024267609号-1</a > 
<br/>
< a href = "https://beian.miit.gov.cn/" target = "_blank" >公安备案号 : </a > 
          < a href = "https://beian.miit.gov.cn/" target = "_blank" ><img src={police} height="15"/>粤公网安备44030002004184号</a > 

          
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
         
        >
          </Grid>
        {/* Social Media and Contact Section */}
        <Grid
          item
          xs={12}
          md={4}
          sx={{ textAlign: { xs: "left", md: "center" } }}
        >
          <Typography
            variant="body"
            component="strong"
            sx={{ marginBottom: "1rem" }}
          >
            追踪特力博微信公众号
          </Typography>
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "left", md: "center" },
              gap: "1rem",
              margin: "2rem 0",
            }}
          >
            {socialMediaInfo?.map((item) => {
              return (
                <IconButton
                  sx={{
                    color: "#000",
                    backgroundColor: "#fff",
                    "&:hover": { backgroundColor: "grey" },
                    width: "48px",
                    height: "48px",
                    padding: 0,
                  }}
                  href={item.href}
                  target="_blank"
                >
                  {" "}
                  <item.component sx={{ fontSize: 24 }} />
                </IconButton>
              );
            })}
          </Box> */}
<div className="footer-links"  style={{paddingTop:'10px'}}>
          <img src={qr} width="30%" alt="qr code"/>
         <div> <a href="mailto:helpcn@teleport.asia">helpcn@teleport.asia</a></div>
         
          </div>
        </Grid>
     
        {/* Customer Care Section */}
        {/* <Grid
          item
          xs={12}
          md={4}
          sx={{ textAlign: { xs: "left", md: "right" } }}
        >
          <Typography
            variant="body"
            component="strong"
            sx={{ marginBottom: "1rem" }}
          >
            {translations.footer8}
          </Typography>
          <Typography variant="body2" component="div">
          {translations.footer9}
            <br />
            9:00AM - 10:00PM
          </Typography>
          <Typography
            variant="body2"
            component="div"
            sx={{ marginTop: "1rem" }}
          >
            {translations.footer10}
            <br />
            9:00AM - 6:00PM
          </Typography>
          <Typography
            variant="body2"
            component="div"
            sx={{ marginTop: "2rem" }}
          >
            <Button
              variant="contained"
              sx={{
                color: "#fff",
                textDecoration: "underline",
                paddingLeft: { xs: 0 },
              }}
              href="https://www.teleport.asia/en/my/privacy?_gl=1*mcgzdu*_gcl_au*MTI3NTcwNDkwNS4xNzEzOTQyMzY4*_ga*Nzk0MzYxODA2LjE3MDkyNjc1ODE.*_ga_Y9KFV7E1F9*MTcxOTM4MzI1Mi4xMy4xLjE3MTkzODMzNTcuMC4wLjA."
            >
              {translations.footer11}
            </Button>
          </Typography>
        </Grid> */}
      </Grid>

    </Box>
  );
};

export default Footer;
