import React from "react";
import {
  Grid,
  Box,
  Typography,
  CardMedia,
} from "@mui/material";
import flashExpress from "../assets/flashExpress.png";
import Ninjavan from "../assets/Ninjavan.png";
import shopee from "../assets/shopeelogo.png";
import shape from "../assets/Shape.svg";
import nx from "../assets/n_x.png";
import jdCom from "../assets/jdcom.png";
import ceva from "../assets/CEVA_Logistics.png";
import dbSchenker from "../assets/DB_Schenker.png";
import { useLanguage } from '../context/LanguageContext';

const BrandsWeMove = () => {
  const { translations } = useLanguage();
  return (
    <Box
      sx={{
        minHeight: "50vh",
        backgroundColor: "#fff",
        display: "inline-block",
        width: "100%",
      }}
    >
      <Typography
        sx={{
          //   marginBottom: "10rem",
          backgroundImage: `url(${shape})`,
          marginTop: "-1px",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          padding: { xs: "2rem", md: "10rem" },
          backgroundPosition: "center top",
          color: "#000",
        }}
        variant="h2"
        align="center"
        gutterBottom
      >
       我们服务范围涵盖企业,<br /> 电子商务和货运代理
      </Typography>
      <Box sx={{ margin: { xs: "2rem", md: "10rem" } }}>
        <Grid container spacing={2} justifyContent="center">
          {/* Top row with 4 images */}
          <Grid item xs={6} sm={3}>
            <CardMedia
              sx={{ width: "70%" }}
              component="img"
              image={flashExpress}
              alt="flashExpress"
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <CardMedia
              sx={{ width: "70%" }}
              component="img"
              image={Ninjavan}
              alt="Ninjavan"
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <CardMedia
              sx={{ width: "70%" }}
              component="img"
              image={shopee}
              alt="shopee"
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <CardMedia
              sx={{ width: "70%" }}
              component="img"
              image={jdCom}
              alt="jdcom"
            />
          </Grid>

          {/* Bottom row with 3 images */}
          <Grid item xs={false} sm={false} md={1} />
          <Grid item xs={5} sm={3}>
            <CardMedia
              sx={{ width: "70%", paddingTop: { md: "38px" } }}
              component="img"
              image={dbSchenker}
              alt="dbSchenker"
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <CardMedia
              sx={{ width: "70%", paddingTop: { md: "42px" } }}
              component="img"
              image={nx}
              alt="nipon express"
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <CardMedia
              sx={{ width: "70%", paddingTop: { md: "16px" } }}
              component="img"
              image={ceva}
              alt="ceva"
            />
          </Grid>
          <Grid item xs={false} sm={1} />
        </Grid>
      </Box>
    </Box>
  );
};
export default BrandsWeMove;
