import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./utils/theme";
import "./App.css";
import { LanguageProvider } from "./context/LanguageContext";
import NotFound from "./components/notFound"; 
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/home"
import Footer from "./components/Footer";
import Header from "./components/header";


function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
      <LanguageProvider>
      <Header />
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
      </LanguageProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
