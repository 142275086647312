import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

const Header = () => {


  return (
    <AppBar position="static" style={{ backgroundColor: 'black', padding:'20px' }}>
      <Toolbar>
       
      </Toolbar>
    </AppBar>
  );
};

export default Header;
