import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import flightImage from "../assets/flightbg.png";
import teleportit from "../assets/teleportit.png";
import { useLanguage } from '../context/LanguageContext';
import tagline from '../assets/tagline.svg'
import { Padding } from "@mui/icons-material";

const BackgroundBox = styled(Box)(({ theme, isScrolled }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  backgroundImage: `url(${flightImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  transform: isScrolled
    ? "translatey(-5%) scale(1.1)"
    : "translatey(0) scale(1.1)",
  transition: "all 700ms",
  "@media (max-width:767px)": {
    backgroundPosition: "right",
  },
}));

const CenteredText = styled(Typography)({
  position: "absolute",
  top: "50%",
  left: "10%",
  transform: "translateY(-50%)",
  color: "white",
  width: "700px",
  textAlign: "left",
  fontSize: "66px",
  "@media (max-width:767px)": {
    width: "100%",
    top: "80%",
    left:0,
    padding:'0px 30px',
    
  },
});
const Banner = styled("div")({
  overflow: "hidden",
  height: "620px",
  position: "relative",
  minHeight: "620px",
});
const SubHeader = styled("div")({
  marginTop: "50px",
  "@media (max-width:768px)": {
    marginTop: "20px",
  },
});
const StyledImage = styled("img")(({ theme }) => ({
  width: "300px",
  maxWidth: "45%",
  [theme.breakpoints.down("sm")]: {
    width: "150px",
  },
}));

const LandingBanner = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const { translations } = useLanguage();
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 100) {
        // Change 100 to the scroll position at which you want to add the class
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <Banner>
      <BackgroundBox isScrolled={isScrolled} />
      <CenteredText variant="h2">
      <img src={tagline} alt="Southeast Asia's logistics."/>
       
      </CenteredText>
    </Banner>
  );
};

export default LandingBanner;
