import React from "react";
import {
  Grid,
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import nextDay from "../assets/nexDay.png";
import teleportSolutions from "../assets/teleportSolutions.png";
import aircargo from "../assets/aircargo.png";
import partners from "../assets/partners.jpg"
import { useLanguage } from '../context/LanguageContext';

const MovementOnTheWay = () => {
  const { translations } = useLanguage();
  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f4",
        color: "black",
        minHeight: "100vh",
        padding: "2rem",
      }}
    >
      <Typography
        sx={{ marginTop: "5rem", fontWeight: 500, fontSize: "20px" }}
        align="center"
        gutterBottom
      >
      无论货物大与小, 我们都载
      </Typography>
      <Typography
        sx={{
          margin: { xs: "2rem 0 5rem 0", md: "2rem 10rem 5rem 10rem " },
          fontWeight: 500,
        }}
        variant="h3"
        align="center"
        gutterBottom
      >
       我们的混合式综合机队可替您 <br/>提供端到端的物流服务。
      </Typography>
      <Grid
        container
        spacing={4}
        direction={{ xs: "row", md: "column" }}
        alignItems="center"
      >
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              boxShadow: "none",
              backgroundColor: "transparent",
              height: { md: 320 },
              width: { md: 1000 },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: { md: "50%" },
              }}
            >
              <CardMedia
                component="img"
                sx={{ width: "100%", height: "auto", borderRadius: "5px" }}
                image={teleportSolutions}
                alt="teleportSolutions"
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: { md: "50%" },
              }}
            >
              <CardContent
                sx={{
                  flex: "1",
                  paddingLeft: { xs: 0, md: 10 },
                  paddingTop: { xs: 2, md: 10 },
                  textAlign: "left",
                  color: "#000",
                }}
              >
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{ fontWeight: "bold" }}
                >
                 特力博 智能方案
                </Typography>
                <Typography variant="body1" gutterBottom>
                专业的定制化端到端物流服务。
                </Typography>
              </CardContent>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              boxShadow: "none",
              backgroundColor: "transparent",
              height: { md: 320 },
              width: { md: 1000 },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: { md: "50%" },
              }}
            >
              <CardMedia
                component="img"
                sx={{ width: "100%", height: "auto", borderRadius: "5px" }}
                image={aircargo}
                alt="aircargo"
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: { md: "50%" },
              }}
            >
              <CardContent
                sx={{
                  flex: "1",
                  paddingLeft: { xs: 0, md: 10 },
                  paddingTop: { xs: 2, md: 10 },
                  textAlign: "left",
                  color: "#000",
                }}
              >
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{ fontWeight: "bold" }}
                >
                 特力博 航空货运
                </Typography>
                <Typography variant="body1" gutterBottom>
                以东南亚最大的航空网络连通至东南亚各个城市。
                </Typography>
              </CardContent>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              boxShadow: "none",
              backgroundColor: "transparent",
              height: { md: 320 },
              width: { md: 1000 },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: { md: "50%" },
              }}
            >
              <CardMedia
                component="img"
                sx={{ width: "100%", height: "auto", borderRadius: "5px" }}
                image={partners}
                alt="partners"
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: { md: "50%" },
              }}
            >
              <CardContent
                sx={{
                  flex: "1",
                  paddingLeft: { xs: 0, md: 10 },
                  paddingTop: { xs: 2, md: 10 },
                  textAlign: "left",
                  color: "#000",
                }}
              >
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{ fontWeight: "bold" }}
                >
                特力博 航空伙伴
                </Typography>
                <Typography variant="body1" gutterBottom>
                在东南亚最佳的航空合作伙伴，确保您永不空载。
                </Typography>
              </CardContent>
            </Box>
          </Card>
        </Grid>


        <Grid item xs={12} md={6}>
          <Card
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              boxShadow: "none",
              backgroundColor: "transparent",
              height: { md: 320 },
              width: { md: 1000 },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: { md: "50%" },
              }}
            >
              <CardMedia
                component="img"
                sx={{ width: "100%", height: "auto", borderRadius: "5px" }}
                image={nextDay}
                alt="nextDay"
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: { md: "50%" },
              }}
            >
              <CardContent
                sx={{
                  flex: "1",
                  paddingLeft: { xs: 0, md: 10 },
                  paddingTop: { xs: 2, md: 10 },
                  textAlign: "left",
                  color: "#000",
                }}
              >
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{ fontWeight: "bold" }}
                >
                  特力博 次日送达
                </Typography>
                <Typography variant="body1" gutterBottom>
                覆盖吉隆坡、曼谷、新加坡价钱划算的次日送货上门服务。
                </Typography>
              </CardContent>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MovementOnTheWay;
