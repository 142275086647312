import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, Arial, sans-serif',
    h1: {
      fontWeight: 600,
    },
    h2: {
      fontWeight: 600,
      '@media (max-width:767px)': {
        fontSize: '30px',
      },
    },
    h3: {
      fontWeight: 600,
      '@media (max-width:767px)': {
        fontSize: '22px',
      },
    },
    h4: {
      fontWeight: 600,
      '@media (max-width:767px)': {
        fontSize: '1rem',
      },
    },
    h5: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 600,
    },
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    primary: {
      main: '#000',
    },
    secondary: {
      main: '#122E92',
    },
    background: {
      default: '#000',
    },
    text: {
      primary: '#fff',
    },
  },
});

export default theme;
