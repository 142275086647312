import axios from 'axios';
import { embedDashboard } from "@superset-ui/embedded-sdk";



    function SupersetEmbed() {

      return  <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          //  backgroundColor: '#f4f4f9', // light background
            padding: '20px'  // space around the iframe
          }}>
            <iframe 
              src="https://data.teleport.asia/superset/dashboard/p/W8MGEVzyZ3q/"
              style={{
                width: '90%',
                height: '90%',
                border: '1px solid #ddd',
                borderRadius: '10px',   // rounded corners
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',  // subtle shadow
              }} 
              title="Superset Dashboard"
              allowFullScreen
            />
          </div>
    }

export default SupersetEmbed;
